<template>
  <div class="bg-white sm:bg-main-bg min-h-full h-screen pb-12">
    <div class="px-4 lg:px-0 font-medium h-24 flex flex-col justify-center">
      <div class="max-w-4xl mx-auto w-full">
        <span @click.prevent="returnToHomePage" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" />
        </span>
      </div>
    </div>
    <div class="max-w-md mx-auto px-4 md:px-0 mt-10">
      <div
        class="px-0 max-w-md sm:border border-border bg-white sm:py-8 sm:px-6 rounded-lg mx-auto"
      >
        <h3
          v-if="!emailSent"
          ref="forgotTitle"
          class="font-medium text-3x1 login--title text-title"
        >
          Forgot Password?
        </h3>
        <p
          v-if="!emailSent"
          ref="forgotText"
          class="pb-4 font-medium text-sm text-sec-text w-9/12 md:w-full"
        >
          Enter your email address to receive a password reset link.
        </p>
        <form
          v-if="!emailSent"
          ref="form"
          @submit.prevent="initiatePasswordReset"
          class=""
        >
          <div class="flex flex-col input-wrap">
            <label class="text-xs font-medium block text-sec-text mb-1"
              >Email Address</label
            >
            <div class="flex items-center bg-main-bg w-full h-12 mb-12">
              <svg
                class="absolute ml-4"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999995 0H13C13.1768 0 13.3464 0.0702379 13.4714 0.195262C13.5964 0.320286 13.6667 0.489856 13.6667 0.666667V11.3333C13.6667 11.5101 13.5964 11.6797 13.4714 11.8047C13.3464 11.9298 13.1768 12 13 12H0.999995C0.823184 12 0.653615 11.9298 0.52859 11.8047C0.403566 11.6797 0.333328 11.5101 0.333328 11.3333V0.666667C0.333328 0.489856 0.403566 0.320286 0.52859 0.195262C0.653615 0.0702379 0.823184 0 0.999995 0ZM12.3333 2.82533L7.04799 7.55867L1.66666 2.81067V10.6667H12.3333V2.82533ZM2.00733 1.33333L7.04066 5.77467L12.0013 1.33333H2.00733Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                @change.prevent="borderFill"
                class="bg-transparent w-full h-full text-sec-text border border-input focus:border-primary hover:border-primary outline-none rounded-lg text-sm pl-10 pr-4"
                type="email"
                name="Email"
                autocomplete="on"
                required
                v-model="email"
                placeholder="Enter email address"
              />
            </div>
          </div>
          <button
            id="cta-btn"
            class="btn flex items-center justify-center bg-primary w-full rounded-lg h-14 hover:bg-hover text-white font-medium text-sm"
          >
            <span id="spin" slot="spin" class="animate-spin hidden">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                  fill="white"
                  fill-opacity="0.7"
                />
              </svg>
            </span>
            <span id="check" slot="check" class="hidden mr-1">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 13.6666C3.31816 13.6666 0.333496 10.6819 0.333496 6.99992C0.333496 3.31792 3.31816 0.333252 7.00016 0.333252C10.6822 0.333252 13.6668 3.31792 13.6668 6.99992C13.6668 10.6819 10.6822 13.6666 7.00016 13.6666ZM7.00016 12.3333C8.41465 12.3333 9.77121 11.7713 10.7714 10.7712C11.7716 9.77096 12.3335 8.41441 12.3335 6.99992C12.3335 5.58543 11.7716 4.22888 10.7714 3.22868C9.77121 2.22849 8.41465 1.66659 7.00016 1.66659C5.58567 1.66659 4.22912 2.22849 3.22893 3.22868C2.22873 4.22888 1.66683 5.58543 1.66683 6.99992C1.66683 8.41441 2.22873 9.77096 3.22893 10.7712C4.22912 11.7713 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66659L3.50683 6.83792L4.4495 5.89525L6.3355 7.78125L10.1062 4.00992L11.0495 4.95259L6.3355 9.66659Z"
                  fill="white"
                />
              </svg>
            </span>
            <span id="login" slot="value" class="w-full py-4">
              {{ buttonText }}
            </span>
          </button>
        </form>
        <div
          v-if="emailSent"
          class="flex-col items-center bg-white w-full"
        >
          <h3 class="font-medium text-3x1 login--title text-black">
            Password reset link sent
          </h3>
          <p class="pt-6 pb-16 font-medium text-sm text-text md:w-fulls">
            We have sent a secure password reset link to this email address.
            <br />You would be able to reset your password by clicking the link
            in the email you receive.
          </p>
          <span
            id="back-btn"
            @click.prevent="emailSent = false"
            class="bg-main-bg border cursor-pointer border-input w-full rounded-md h-12 text-primary font-medium text-sm flex items-center justify-center tracking-wide"
          >
              Go back
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { AUTH_ROUTES } from "../../constants";
  import Cookies from "js-cookie";
  import { ApiInstance as api } from "../../utils";

  export default {
    name: "ForgotPassword",
    data() {
      return {
        success: "Success",
        email: null,
        buttonText: "Reset Password",
        emailSent: false,
      };
    },
    head: {
      title: function () {
        return {
          inner: "Loanspot",
          complement: "Reset password",
        };
      },
      meta: [
        { name: "description", content: "Loanspot reset your password" },
        { name: "application-name", content: "Loanspot" },
      ],
    },
    methods: {
      borderFill(e) {
        if (e.target.value === "") {
          e.target.style.border = "1px solid rgba(0, 0, 0, 0.04)";
        } else {
          e.target.style.border = "1px solid rgba(44, 71, 156, 0.56)";
        }
      },
      async initiatePasswordReset(e) {
        e.preventDefault();
        const spinner = document.getElementById("spin");
        const loginBtn = document.getElementById("login");
        spinner.classList.remove("hidden");
        loginBtn.classList.add("hidden");

        try {
          const response = await api.post("/auth/password/reset/initiate", {
            email: this.email,
          });
          spinner.classList.add("hidden");
          loginBtn.classList.remove("hidden");
          if (response.status && response.data?.statusCode === 200) {
            this.emailSent = true;
            this.email = "";
            this.$notie.alert({
              type: "success",
              text: "Password reset link has been sent.",
              time: 4,
            });
          } else {
            this.$notie.alert({
              type: "error",
              text: "Password reset link could not be sent.",
              time: 4,
            });
          }
        } catch (error) {
          this.$notie.alert({
            type: "error",
            text: "Password reset link could not be sent.",
            time: 4,
          });
        }
      },
      backToSite() {
        window.location.assign(AUTH_ROUTES.LOGIN);
      },
      returnToHomePage() {
        const token = localStorage.getItem("auth-token");

        if (token) {
          Cookies.set("auth-token", token, {
            path: "/",
            domian: ".loanspot.africa",
            sameSite: "lax",
          });
          // window.location.assign(`http://localhost:5501`);
          window.location.assign(`https://loanspot.africa`);
        } else {
          window.location.assign(`https://loanspot.africa`);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.header-wrap {
  padding: 35px 10%;
}
@media (min-width: 1440px) {
  .header-wrap {
    max-width: 1440px !important;
    margin: auto;
  }
}
.form-wrap {
  padding: 8px 10% 32px;
  p {
    font-family: SohneLeicht, sans-serif;
  }
  @media (min-width: 640px) {
    padding: 42px 10% 48px;
  }
}
.login--register {
  font-family: SohneKraftig, sans-serif;
  font-size: 1rem;
}
.login--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}
.password-reset {
  font-family: SohneLeicht, sans-serif;
}
.input-wrap {
  font-family: SohneLeicht, sans-serif;
  label {
    font-family: SohneKraftig, sans-serif;
    margin-bottom: 4px;
  }
  span {
    font-family: SohneLeicht, sans-serif;
  }
}
</style>